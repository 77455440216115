/**
 * Базовый функционал для умных компонентов, содержит внутри себя пайплайн для
 * прохождения запроса и переключения статусов запроса
 */

export default {
  data() {
    return {
      /**
       * Все свойства объекта externalAPI попадут в slot-scope компонента при его
       * вызове, но сюда нельзя класть функции, для их передачи через slot-scope используется отдельный метод appendMethodToAPI
       */
      externalAPI: {
        status: 'initial',
        initialLoad: this.initialLoad,
        initial: true,
        loading: this.loading,
        success: this.success,
        error: this.error
      }
    }
  },
  created() {
    /**
     * appendMethodToAPI нужно вызывать в хуке created, чтобы передать нужные функции через slot-scope
     */
    this.appendMethodToAPI({ name: 'changeStatus', fn: this.changeStatus })
  },
  methods: {
    /**
     * Сохранять функции в объекте externalAPI нельзя т.к. накст пытается
     * их сериализовать при передаче на клиент и из функций делает объекты, поэтому для передачи
     * функций в slot-scope компонента при вызове мы используем этот метод
     * @param {string} obj.name - название функции
     * @param {function} obj.fn - функция, которая будет вызвана
     */
    appendMethodToAPI({ name, fn } = {}) {
      if (!this._api) this._api = {}
      this._api[name] = fn
    },
    /**
     * Ручная смена статуса
     * @param {string} status - новый статус: initial, error, loading или success
     */
    changeStatus(status) {
      this.resetStatus()
      this.externalAPI[status] = true
      this.externalAPI.status = status
    },
    resetStatus() {
      this.externalAPI.initial = false
      this.externalAPI.loading = false
      this.externalAPI.success = false
      this.externalAPI.error = false
    },
    /**
     * Основной пайплайн для запроса, на разных этапах меняем статус, вызываем каллбэки
     */
    async request({ api = () => Promise.resolve(), onLoad = () => {}, onSuccess = () => {}, onError = () => {} } = {}) {
      if (this.externalAPI.loading) return
      onLoad()
      this.changeStatus('loading')
      this.$emit(`loading`, { ...this.externalAPI, ...this._api })
      try {
        const response = await api()
        onSuccess(response)
        this.changeStatus('success')
        this.$emit(`success`, { ...this.externalAPI, ...this._api })
      } catch (error) {
        console.log(error)
        onError(error)
        this.changeStatus('error')
        this.$emit(`error`, { ...this.externalAPI, ...this._api })
      }
    }
  },
  render() {
    return this.$scopedSlots.default({ ...this.externalAPI, ...this._api })
  }
}
