//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CheckboxList } from '05-ui-kit'

export default {
  components: {
    CheckboxList
  },
  props: {
    vacancies: {
      type: String,
      default: '-'
    },
    birthday: {
      type: String,
      default: '-'
    },
    name: {
      type: String,
      default: '-'
    },
    phone: {
      type: String,
      default: '-'
    },
    email: {
      type: String,
      default: '-'
    },
    registration_address: {
      type: String,
      default: '-'
    },
    current_address: {
      type: String,
      default: '-'
    },
    bio: {
      type: String,
      default: '-'
    },
    study: {
      type: Array,
      default: '-'
    },
    work: {
      type: Array,
      default: '-'
    },
    isPrint: {
      type: Boolean
    }
  },
  computed: {
    periodOne() {
      return `${this.work[0] ? this.work[0].from : ''} ${this.work[0] && this.work[0].to ? '-' + this.work[0].to : ''}`
    },
    periodTwo() {
      return `${this.work[1] ? this.work[1].from : ''} ${this.work[1] && this.work[1].to ? '-' + this.work[1].to : ''}`
    }
  }
}
