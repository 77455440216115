//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  Form,
  FormElement,
  InputText,
  Textarea,
  Button,
  Note,
  Select,
  Toggle,
  RadioList,
  Checkbox,
  Modal,
  FileInput as File
} from '05-ui-kit'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import saveStateToStorage from '05-ui-kit/lib/saveStateToStorage'

export default {
  components: {
    Form,
    FormElement,
    InputText,
    Textarea,
    Button,
    ValidationProvider,
    ValidationObserver,
    Select,
    Toggle,
    RadioList,
    Checkbox,
    Modal,
    File,
    Note
  },
  layout: 'seeker',
  mixins: [saveStateToStorage([{ name: 'responseFormData', fields: 'dataWrapp' }])],
  mounted() {
    this.$metrika.hit(this.$route.fullPath)
  },
  head: {
    title: 'Создание заявки',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: 'Оставьте заявку и мы её детально изучим'
      }
    ]
  },
  data() {
    return {
      status: false,
      dataWrapp: {
        data: {
          full_name: null,
          phone: null,
          email: null,
          birthday: null,
          vacancies: [],
          study_places: [
            {
              name: null,
              specialty: null
            }
          ],
          work_places: [
            {
              company_name: null,
              position: null,
              work_period_from: null,
              work_period_to: null
            }
          ],
          employment_type: 1,
          current_address: null,
          registration_address: null,
          bio: null
        },
        birthday: {
          day: null,
          month: null,
          year: null
        },
        dateCompanies: [{ work_period_from: null, work_period_to: null }]
      },
      birthdayError: [],
      birthdayData: null,
      vacancies: null,
      resume: null,
      isShowModal: false,
      isAgree: false
    }
  },
  async created() {
    // Временно, пока вакансии с сайта и с рекрутинга не будут синхронизированы
    // if (this.$route.query?.['vacancy-id']) {
    //   this.dataWrapp.data.vacancies.push(+this.$route.query?.['vacancy-id'])
    // }
    this.birthdayData = this.birthdayHandler(this.dataWrapp.birthday)
  },
  computed: {
    vacanciesList() {
      return this.vacancies
        ?.filter((item) => this.dataWrapp.data.vacancies.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    }
  },
  watch: {
    'dataWrapp.data.phone'(phone, oldPhone) {
      /**
       * nextTick чтоб выполнить замену смиволов после директивы маски
       * но до отрисовки.
       * oldPhone чтоб при стирании не было замен
       */
      this.$nextTick(() => {
        if (!oldPhone && ['7', '8', '+'].includes(phone)) {
          this.dataWrapp.data.phone = '+7 (9'
        }
      })
    }
  },
  methods: {
    async onInput(val, index) {
      if (val === 'day') {
        this.dataWrapp.birthday.day = await this.dataWrapp.birthday.day.replace(/[A-Za-zА-Яа-яЁё]/, '')
        if (this.dataWrapp.birthday.month === '02' && +this.dataWrapp.birthday.day > 29) {
          this.dataWrapp.birthday.day = '29'
        } else if (+this.dataWrapp.birthday.day > 31) {
          this.dataWrapp.birthday.day = '31'
        }
      } else if (val === 'year') {
        this.dataWrapp.birthday.year = await this.dataWrapp.birthday.year.replace(/[A-Za-zА-Яа-яЁё]/, '')
      } else if (val === 'period-from') {
        this.dataWrapp.dateCompanies[index].work_period_from = await this.dataWrapp.dateCompanies[index].work_period_from.replace(
          /[A-Za-zА-Яа-яЁё]/,
          ''
        )
      } else if (val === 'period-to') {
        this.dataWrapp.dateCompanies[index].work_period_to = await this.dataWrapp.dateCompanies[index].work_period_to.replace(
          /[A-Za-zА-Яа-яЁё]/,
          ''
        )
      }
    },
    onBlur(val) {
      if (val === 'day' && typeof this.dataWrapp.birthday.day === 'string') {
        if (this.dataWrapp.birthday.day.length > 2) {
          this.dataWrapp.birthday.day = this.dataWrapp.birthday.day.substr(0, 2)
        } else if (+this.dataWrapp.birthday.day > 31) {
          this.dataWrapp.birthday.day = '31'
        } else if (this.dataWrapp.birthday.day.length === 1) {
          this.dataWrapp.birthday.day = '0' + this.dataWrapp.birthday.day
        }
      } else if (val === 'year' && typeof this.dataWrapp.birthday.year === 'string') {
        if (this.dataWrapp.birthday.year?.length !== 0 && this.dataWrapp.birthday.year?.length < 4) {
          let tmp = [0, 0, 0, 0]
          this.dataWrapp.birthday.year.split('').forEach((el, index) => {
            if (index < 4) {
              tmp[index] = el
            }
          })
          this.dataWrapp.birthday.year = tmp.join('')
        }
      }
      this.onBirthday()
    },
    onBirthday() {
      if (
        Boolean(this.dataWrapp.birthday.day) === false ||
        Boolean(this.dataWrapp.birthday.year) === false ||
        Boolean(this.dataWrapp.birthday.month) === false
      ) {
        this.birthdayError = ['Заполните дату рождения']
      } else {
        this.birthdayError = []
      }
      if (this.dataWrapp.birthday.month === '02' && +this.dataWrapp.birthday.day > 29) {
        this.dataWrapp.birthday.day = '29'
      }
      this.$set(
        this.dataWrapp.data,
        'birthday',
        `${this.dataWrapp.birthday.year}-${this.dataWrapp.birthday.month}-${this.dataWrapp.birthday.day}`
      )
      this.birthdayData = this.birthdayHandler(this.dataWrapp.birthday)
    },
    addCompany() {
      this.dataWrapp.data.work_places.push({ company_name: null, position: null, work_period_from: null, work_period_to: null })
      this.dataWrapp.dateCompanies.push({ work_period_from: null, work_period_to: null })
    },
    async onSubmit(send) {
      if (this.dataWrapp.birthday.day === null || this.dataWrapp.birthday.year === null || this.dataWrapp.birthday.month === null) {
        this.birthdayError = ['Заполните дату рождения']
      } else {
        this.birthdayError = []
      }
      this.$refs.observerRef.validateWithInfo().then(({ errors, isValid }) => {
        isValid ? this.postData(send) : this.scrollToInvalidEl(errors)
      })
    },
    postData(send) {
      this.dataWrapp.dateCompanies.forEach((el, index) => {
        this.$set(this.dataWrapp.data.work_places[index], 'work_period_from', el.work_period_from?.split('-').reverse('').join('-'))
        this.$set(this.dataWrapp.data.work_places[index], 'work_period_to', el.work_period_to?.split('-').reverse('').join('-'))
      })

      // Удаляем пустые строки из массива вакансий, потому-что бэк их не обрабатывает из-за чего возникает ошибка отправки
      this.dataWrapp.data.vacancies = this.dataWrapp.data.vacancies.filter((el) => el)

      send({ data: { ...this.dataWrapp.data, resume: this.resume } })
    },
    scrollToInvalidEl(errors) {
      const err = Object.entries(errors)
        .map(([key, value]) => ({ key, value }))
        .filter((error) => error.value.length)
      scrollIntoView(this.$refs.observerRef.refs[err[0].key].$el, {
        behavior: 'smooth',
        block: 'start'
      })
    },
    timeMask(value) {
      const day = [/[0-3]/, value.charAt(0) === '3' ? /[0-1]/ : /[0-9]/]
      const minutes = [/[0-1]/, value.charAt(2) === '0' ? /[0-9]/ : /[0-2]/]
      const year = [/[0-2]/, /[0-9]/, /[0-9]/, /[0-9]/]
      return value.length > 2 ? [...day, '-', ...minutes, '-', ...year] : day
    },
    timeRangeMask(value) {
      if (value) {
        const numbers = value.replace(/[^0-9]/g, '')
        if (numbers.length === 2) {
          return [...this.timeMask(numbers.substring(0, 2)), '-', ...this.timeMask(numbers.substring(2))]
        }
        return [...this.timeMask(numbers)]
      }
    },
    birthdayHandler(val) {
      const month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
      let temp = { ...val }
      temp.month = month[+val.month - 1]
      return Object.values(temp).join(' ')
    },
    onSuccess({ response }) {
      this.vacancies = response.list
    },
    replaceProperties(arr, prop) {
      return arr.map((obj) => ({ name: obj[prop], id: obj.id }))
    },
    onSuccessCreate() {
      this.removeStateFromStore('responseFormData')
    }
  }
}
