//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'

export default {
  mounted() {
    window.addEventListener('beforeprint', this.enable)
    window.addEventListener('afterprint', this.disable)
  },
  destroyed() {
    window.removeEventListener('beforeprint', this.enable)
    window.removeEventListener('afterprint', this.disable)
  },
  computed: mapState({
    isPrint: (state) => state.print.isPrint
  }),
  methods: {
    ...mapMutations({
      enable: 'print/enable',
      disable: 'print/disable'
    }),
    enableHandler() {
      this.enable()
      this.$nextTick(() => {
        window.print()
      })
    }
  }
}
