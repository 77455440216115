//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Modal } from '05-ui-kit'
export default {
  components: {
    Button,
    Modal
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
