
/**
 * Базовый дата-компонент для получения сущности из api
 */

import dataEntityMixin from '~/mixins/dataEntityMixin'

export default {
  mixins: [dataEntityMixin]
}
